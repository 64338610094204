import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import { navigate } from "@reach/router";
import Axios from 'axios';
import Moment from 'react-moment';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import components
import RoundButton from '../components/RoundButton.js';

class HomePage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            days: 90,
            topSongsArray: ([{"id_song":0,"title":"Loading...","count":0}]),
            musiciansPlayedArray: ([{"id_musician":0,"name":"Jméno","surname":"Přijmení","count":0}]),
        }
    }

    componentDidMount(){
        this.loadData();
    }

    loadData = () => {
        Axios.get('/api/statistics/topSongs?days='+this.state.days).then(response => {
            if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
            if(!response.data.result)return;
            this.setState({ topSongsArray: response.data.result });
        });

        Axios.get('/api/statistics/musiciansPlayed?days='+this.state.days).then(response => {
            if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
            if(!response.data.result)return;
            this.setState({ musiciansPlayedArray: response.data.result });
        });

    }

    updateNumOfDays = async (days) => {
        await this.setState({ days: days });
        this.loadData();
    }

    render() {
        return (
            <div className="verticalStack flex" style={{height: "calc(100vh - 80px)", overflowY: "scroll"}}>

                <div className="universalTile">
                    <div className="horizontalStack">
                        <div className="verticalStack">
                            <span className="fontPoppinsSemiBold15">{"Statistiky"}</span>
                            <span className="fontPoppinsRegular13Gray">{"Zde jsou statistiky vašeho týmu."}</span>
                        </div>
                        <div style={{width: 30}}></div>
                        <RoundButton title={"Posledních 7 dnů"} style={{color: "white", backgroundColor: this.state.days == 7 ? "#e8eafb" : "#d4daf9"}} onClick={() => this.updateNumOfDays(7)} whiteText={false} />
                        <div style={{width: 10}}></div>
                        <RoundButton title={"Posledních 28 dnů"} style={{color: "white", backgroundColor: this.state.days == 28 ? "#e8eafb" : "#d4daf9"}} onClick={() => this.updateNumOfDays(28)} whiteText={false} />
                        <div style={{width: 10}}></div>
                        <RoundButton title={"Posledních 90 dnů"} style={{color: "white", backgroundColor: this.state.days == 90 ? "#e8eafb" : "#d4daf9"}} onClick={() => this.updateNumOfDays(90)} whiteText={false} />
                        <div style={{width: 10}}></div>
                        <RoundButton title={"Poslední rok"} style={{color: "white", backgroundColor: this.state.days == 365 ? "#e8eafb" : "#d4daf9"}} onClick={() => this.updateNumOfDays(365)} whiteText={false} />
                        <div style={{width: 10}}></div>
                        <RoundButton title={"Celkově"} style={{color: "white", backgroundColor: this.state.days == 365000 ? "#e8eafb" : "#d4daf9"}} onClick={() => this.updateNumOfDays(365000)} whiteText={false} />
                    </div>


                </div>

                <div className="universalTile">
                    <div className="verticalStack">
                        <span className="fontPoppinsSemiBold15">{"Top 10 nejhranějších chval za posledních "+this.state.days+" dní."}</span>
                        <div style={{marginTop: 10}}>
                            <table className="listTable" style={{width: "auto"}}>
                                <tr>
                                    <td className="listTableHeader listTableNameHeader" style={{paddingTop: 8, paddingBottom: 8}}>Název chvály</td>
                                    <td className="listTableHeader listTableNameHeader" style={{paddingTop: 8, paddingBottom: 8}}>Počet hraní za dané období</td>
                                </tr>

                                {this.state.topSongsArray.map(function (item) {
                                    return (
                                        <tr>
                                             <td className="listTableGeneralDiv" nowrap="nowrap" style={{paddingTop: 8, paddingBottom: 8}}>{item.title}</td>
                                             <td className="listTableGeneralDiv" nowrap="nowrap" style={{paddingTop: 8, paddingBottom: 8}}>{item.count}x</td>
                                        </tr>
                                    )
                                }.bind(this))}
                            </table>
                        </div>
                    </div>
                </div>

                <div className="universalTile" style={{marginBottom: 20}}>
                    <div className="verticalStack">
                        <span className="fontPoppinsSemiBold15">{"Kolikrát kdo hrál za posledních "+this.state.days+" dní."}</span>
                        <div style={{marginTop: 10}}>
                            <table className="listTable" style={{width: "auto"}}>
                                <tr>
                                    <td className="listTableHeader listTableNameHeader" style={{paddingTop: 8, paddingBottom: 8}}>Jméno a přijmení</td>
                                    <td className="listTableHeader listTableNameHeader" style={{paddingTop: 8, paddingBottom: 8}}>Počet hraní za dané období</td>
                                </tr>

                                {this.state.musiciansPlayedArray.map(function (item) {
                                    return (
                                        <tr>
                                             <td className="listTableGeneralDiv" nowrap="nowrap" style={{paddingTop: 8, paddingBottom: 8}}>{item.name} {item.surname}</td>
                                             <td className="listTableGeneralDiv" nowrap="nowrap" style={{paddingTop: 8, paddingBottom: 8}}>{item.count}x</td>
                                        </tr>
                                    )
                                }.bind(this))}
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default HomePage;
