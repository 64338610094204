import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import { Link, Navigate } from "react-router-dom";
import { getInitials, showSortIcon } from '../utilities/OtherUtilities.js';
import Moment from 'react-moment';

class SongsTile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            windowHeight: 1000,
        }
        this.refScroll = React.createRef();
    }

    async componentDidMount(){
        this.setState({ redirectTo: null });
        window.addEventListener('resize', this.checkWindowWidth);

        this.checkWindowWidth();
        await new Promise(resolve => setTimeout(resolve, 50));
        this.checkWindowWidth();

        this.refScroll.current.scrollTo(0, Number(localStorage.getItem("songsListScroll")) || 0);
        await new Promise(resolve => setTimeout(resolve, 100));
        this.refScroll.current.scrollTo(0, Number(localStorage.getItem("songsListScroll")) || 0);
        await new Promise(resolve => setTimeout(resolve, 300));
        this.refScroll.current.scrollTo(0, Number(localStorage.getItem("songsListScroll")) || 0);
        await new Promise(resolve => setTimeout(resolve, 500));
        this.refScroll.current.scrollTo(0, Number(localStorage.getItem("songsListScroll")) || 0);
        await new Promise(resolve => setTimeout(resolve, 1000));
        this.refScroll.current.scrollTo(0, Number(localStorage.getItem("songsListScroll")) || 0);
    }

    componentWillUnmount() { window.removeEventListener('resize', this.checkWindowWidth); }
    checkWindowWidth = () => { this.setState({ windowHeight: window.innerWidth }); }

    openSongPage = (id) => {
        this.setState({ redirectTo: "/Song?id="+id });
    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }

        return (
            <div style={{height: "calc(100% - 60px)", width: "100%"}}>
                <div className="listTile">
                    <div ref={this.refScroll} onScroll={() => localStorage.setItem("songsListScroll", this.refScroll.current.scrollTop)} style={{width: "calc(100% - 80px)", height: "calc(100% - 300px)", position: "absolute", overflowX: "auto", overflowY: "auto"}}> {/* 240px including the padding */}
                        <table className="listTable">
                            <tr>
                                {this.state.windowHeight >= 900 ? <td className="listTableHeader listTableNameHeader">Název chvály</td> : null }
                                {this.state.windowHeight < 900 ? <td className="listTableHeader listTableHeaderOther">Název chvály</td> : null }
                                {this.state.windowHeight >= 900 ? <td className="listTableHeader listTableHeaderRole" onClick={() => this.props.changeSorting("author")}>Autor {showSortIcon("author",this.props.sortBy,this.props.sortTp)}</td> : null }
                                {this.state.windowHeight >= 900 ? <td className="listTableHeader listTableHeaderOther" onClick={() => this.props.changeSorting("capo")}>Capo {showSortIcon("capo",this.props.sortBy,this.props.sortTp)}</td> : null }
                                {this.state.windowHeight >= 900 ? <td className="listTableHeader listTableHeaderOther" onClick={() => this.props.changeSorting("tempo")}>Tempo {showSortIcon("tempo",this.props.sortBy,this.props.sortTp)}</td> : null }
                                {this.state.windowHeight >= 900 ? <td className="listTableHeader listTableHeaderOther" onClick={() => this.props.changeSorting("song_key")}>Tónina {showSortIcon("song_key",this.props.sortBy,this.props.sortTp)}</td> : null }
                                {this.state.windowHeight >= 900 ? <td className="listTableHeader listTableHeaderOther" onClick={() => this.props.changeSorting("date_created")}>Datum vytvoření {showSortIcon("date_created",this.props.sortBy,this.props.sortTp)}</td> : null }
                                {this.state.windowHeight >= 900 ? <td className="listTableHeader listTableHeaderOther">Společná poznámka</td> : null }
                            </tr>

                            {this.props.songsArray.map(function (item) {
                                return (
                                    <tr key={item.id} onClick={() => this.openSongPage(item.id_song)}>
                                        {this.state.windowHeight >= 900 ? <td className="horizontalStackCenter listTableName listTableNameDiv listTableFirstFixedColumn" nowrap="nowrap"><span>{item.title}</span></td> : null }
                                        {this.state.windowHeight < 900 ? <td className="listTableGeneralDiv" nowrap="nowrap">{item.title}</td> : null }
                                        {this.state.windowHeight >= 900 ? <td className="listTableGeneralDiv" nowrap="nowrap">{item.author}</td> : null }
                                        {this.state.windowHeight >= 900 ? <td className="listTableGeneralDiv" nowrap="nowrap">{item.capo}</td> : null }
                                        {this.state.windowHeight >= 900 ? <td className="listTableGeneralDiv" nowrap="nowrap">{item.tempo}</td> : null }
                                        {this.state.windowHeight >= 900 ? <td className="listTableGeneralDiv" nowrap="nowrap">{item.song_key}</td> : null }
                                        {this.state.windowHeight >= 900 ? <td className="listTableGeneralDiv" nowrap="nowrap"><Moment format="D. M. YYYY">{item.date_created}</Moment></td> : null }
                                        {this.state.windowHeight >= 900 ? <td className="listTableGeneralDiv" nowrap="nowrap">{item.shared_note}</td> : null }
                                    </tr>
                                )
                            }.bind(this))}

                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default SongsTile;
