import React, { useState, useEffect, useMemo } from "react";

// import assets
import icon_action_down from '../assets/icon_action_down.png';
import icon_action_up from '../assets/icon_action_up.png';

export function showSortIcon(attribute, sortBy, sortTp) {
    if(attribute == sortBy){
        if(sortTp == "asc")return(<img style={{width: "10px", height: "6.3px", marginLeft: 3, marginBottom: 2}} src={icon_action_down}/>);
        else if(sortTp == "desc")return(<img style={{width: "10px", height: "6.3px", marginLeft: 3, marginBottom: 2}} src={icon_action_up}/>);
    }
}