import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import Axios from 'axios';
import { Link, Navigate } from "react-router-dom";
import { navigate } from "@reach/router";

// import components
import RoundButton from '../components/RoundButton.js';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import assets
import logo2 from '../assets/logo2.png';
import icon_edit from '../assets/icon_edit.png';
import icon_print from '../assets/icon_print.png';
import icon_expand from '../assets/icon_expand.png';
import icon_delete from '../assets/icon_delete.png';
import icon_add from '../assets/icon_add.png';

const reactStringReplace = require('react-string-replace');

class PublicSong extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            fontSize: Number(localStorage.getItem("savedFontSize")) || 13,
            transpose: 0,
            songId: 0,
            songObject: {"id_song":0,"title":"Title","author":"Author","content":"[L] [O] [A] [D] [I] [N] [G]\r\nPlease wait...","capo":3,"tempo":0,"song_key":"null","shared_note":"","id_team":1,"created_by":1,"date_created":"2021-05-07T14:04:36.000Z","date_edited":"2023-03-02T09:07:58.000Z","original_url":""},
            songTags: ([{"id_tag":"tags not loaded"}]),
            songPrivateNotes: ([{"id_note":0,"id_song":0,"is_musician":0,"content":"private notes not loaded","date_created":"2023-01-01T00:00:00.000Z","date_edited":"2023-01-01T00:00:00.000Z"}]),
            windowHeight: 1000,
            showNumChords: true,
        }
    }

    async componentDidMount(){
        this.loadData();

        this.setState({ redirectTo: null });
        window.addEventListener('resize', this.checkWindowWidth);

        this.checkWindowWidth();
        await new Promise(resolve => setTimeout(resolve, 50));
        this.checkWindowWidth();
    }

    componentWillUnmount() { window.removeEventListener('resize', this.checkWindowWidth); }
    checkWindowWidth = () => { this.setState({ windowHeight: window.innerWidth }); }

    loadData = async () => {
        const { id } = Object.fromEntries(new URLSearchParams(window.location.search));
        if(id != undefined && Number.isInteger(Number(id))){

            this.setState({ songId: Number(id) });

            Axios.post("/api/publicSongs/getOne", {
                id: id,
            }).then(response => {
                if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
                this.setState({ songObject: response.data.result[0] })
            }).then(response => {
                //await new Promise(resolve => setTimeout(resolve, 800));
                this.runTranspose();
            });

            /*Axios.get('/api/songs/get/'+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
                this.setState({ songObject: response.data.result[0] })
            }).then(response => {
                //await new Promise(resolve => setTimeout(resolve, 800));
                this.runTranspose();
            });*/

            Axios.post("/api/publicTags/get", {
                id: id,
            }).then((response) => {
                if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
                this.setState({ songTags: response.data.result })
            });

            /*Axios.get('/api/tags/get/'+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
                this.setState({ songTags: response.data.result })
            });*/

            this.loadCustomNotes();
        }
    }

    runTranspose = async () => {
        const { transpose } = await Object.fromEntries(new URLSearchParams(window.location.search));
        var transposeNumber = 0;
        if(transpose){
            transposeNumber = Number(transpose);
        }
        this.transposeMethod(transposeNumber);
    }

    transposeMethod = async (value) => {
        var transposeArray = ["C","C#","D","D#","E","F","F#","G","G#","A","A#","H"];
        while(value < 0){
            value = value + transposeArray.length;
        }
        let elements = await document.querySelectorAll('.chord');

        for(var i=elements.length; i--;){

            // here I am transposing one single chord
            var chord = elements[i].textContent;

            // if Number Chords is activated then show number chords
            const { showNumChords } = await Object.fromEntries(new URLSearchParams(window.location.search));
            if(showNumChords){
                elements[i].textContent = this.getNumberFromChord(this.state.songObject.song_key, ""+chord) ;
                continue;
            }
            var isMol = false;
            if(chord.includes("m")){
                chord = chord.replace("m", "");
                isMol = true;
            }
            var id = 0;
            for(let oneRow of transposeArray){
                if(oneRow == chord){
                    let newId = (id + value) % transposeArray.length;
                    elements[i].textContent = transposeArray[newId]+(isMol ? "m" : "");
                }
                id++;
            }

        }

    }

    transposeUp = async () => {
        const { transpose } = await Object.fromEntries(new URLSearchParams(window.location.search));
        var transposeNumber = 0;
        var newTranspose = 1;
        if(transpose){
            newTranspose = Number(transpose)+1;
        }
        navigate("/PublicSong?id="+this.state.songId+"&transpose="+newTranspose);
        window.location.reload();
    }

    transposeDown = async () => {
        const { transpose } = await Object.fromEntries(new URLSearchParams(window.location.search));
        var transposeNumber = 0;
        var newTranspose = -1;
        if(transpose){
            newTranspose = Number(transpose)-1;
        }
        navigate("/PublicSong?id="+this.state.songId+"&transpose="+newTranspose);
        window.location.reload();
    }

    textSizeUp = async () => {
        await localStorage.setItem("savedFontSize", Number(this.state.fontSize)+2);
        window.location.reload();
    }

    textSizeDown = async () => {
        localStorage.setItem("savedFontSize", Number(this.state.fontSize)-2);
        window.location.reload();
    }

    printSong = () => {
        var text = this.state.songObject.content;

        const regex = /\[([^\]]+)\]/g;
        const matches = text.match(regex);

        for (const match of matches) {
            const innerText = match.slice(1, -1);
            text = text.replace(match, "<span style=\"color: #556EE6; font-size: "+(this.state.fontSize+5)+"; font-weight: bold;\">"+innerText+"</span>");
        }

        text = text.replace(/\[/g, "").replace(/\]/g, "");

        var content = document.getElementById("divcontents");
        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        //pri.document.write(content.innerHTML);
        pri.document.write("<br/>");
        pri.document.write("<h1 style=\"font-family: Poppins\">"+this.state.songObject.title+"</h1>");
        pri.document.write("<h3 style=\"font-family: Poppins\">Autor: "+this.state.songObject.author+"</h3>");
        pri.document.write("<h3 style=\"font-family: Poppins\">Capo: "+this.state.songObject.capo+", Tónina: "+this.state.songObject.song_key+"</h3>");
        pri.document.write("<p style=\"font-family: Poppins; white-space: pre-wrap\">"+text+"</p>");
        pri.document.close();
        pri.focus();
        pri.print();
    }

    getNumberFromChord = (oktava, chord) => {
        var isSharp = false;
        var isMol = false;

        if(chord.includes("m")){
            chord = chord.replace("m", "");
            isMol = true;
        }
        if(chord.includes("#")){
            chord = chord.replace("#", "");
            isSharp = true;
        }

        var array = ["C","D","E","F","G","A","H","C","D","E","F","G","A","H","C","D","E","F","G","A","H",];

        var index = 0;
        for(let oneKey of array){
            if(oneKey == oktava){break;}
            index++;
        }

        var finalChord = "";

        for(let chordNum = 0; chordNum < array.length; chordNum++) {
            if(array[index+chordNum] == chord){
                finalChord = chordNum+1; // +1 because indexing starts from 0
                break;
            }
        }

        if(isMol){finalChord = finalChord+"m";}
        return finalChord;
    }

    onNumChordsButtonClicked = async () => {
        const { showNumChords } = await Object.fromEntries(new URLSearchParams(window.location.search));
        if(showNumChords){
            navigate("/PublicSong?id="+this.state.songId);
            window.location.reload();
        }
        else {
            navigate("/PublicSong?id="+this.state.songId+"&showNumChords=true");
            window.location.reload();
        }
    }

    goToPublicSongs = () => {
        navigate("/PublicSongs");
        window.location.reload();
    }

    openLogin = () => {
        window.location.href = "/Login";
    }

    transposeToKey = (toKey, fromKey, songId) => {
        if(toKey == "null"){
            navigate("/PublicSong?id="+songId);
            window.location.reload();
            return;
        }
        if(!fromKey){
            alert("Toto transponování nefunguje, protože chvála nemá definovanou základní tóninu.");
            return;
        }

        var array = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "H", "C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "H"];

        var indexFromKey = 0;
        for (let i = 0; i < array.length; i++) {
            indexFromKey = i;
            if(array[i] == fromKey){break;}
        }

        var indexToKey = indexFromKey;
        for (let i = indexFromKey; i < array.length; i++) {
            indexToKey = i;
            if(array[i] == toKey){break;}
        }

        var difference = indexToKey-indexFromKey;
        if(difference > 7) difference = difference - 12;

        navigate("/PublicSong?id="+songId+"&transpose="+difference);
        window.location.reload();
    }

    getKeyThatIsShown = (fromKey) => {
        if(!fromKey){
            return "null";
        }

        const { transpose } = Object.fromEntries(new URLSearchParams(window.location.search));
        var transposeNumber = 0;
        if(transpose)transposeNumber = Number(transpose);

        // get only positive transpose number
        if(transposeNumber < 0) transposeNumber = transposeNumber+12;

        var array = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "H", "C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "H"];

        var indexFromKey = 0;
        for (let i = 0; i < array.length; i++) {
            indexFromKey = i;
            if(array[i] == fromKey){break;}
        }

        var finalIndex = indexFromKey+transposeNumber;

        return array[finalIndex];
    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }
        var replacedText = this.state.songObject.content;

        /*replacedText = reactStringReplace(replacedText, "[br]", (match, i) => (
            <br/>
        ));*/

        replacedText = reactStringReplace(replacedText, /([^[]+(?=]))/g, (match, i) => (
            <span /*key={i}*/ className="fontPoppinsSemiBold15Blue chord" style={{fontSize: (this.state.fontSize+2)}}>{match}</span>
        ));

        replacedText = reactStringReplace(replacedText, "[", (match, i) => (
            null
        ));

        replacedText = reactStringReplace(replacedText, "]", (match, i) => (
            null
        ));
        return (
            <div className="verticalStack flex" style={{height: "100vh", backgroundColor: "#f4f4f8", overflowY: "scroll"}}>
                <div className="mainTopMenu horizontalStackCenter">

                    <img style={{height: "50px"}} src={logo2} className="clickable" onClick={() => {navigate("/");window.location.reload();}}/>

                    <div style={{width: 10}}></div>

                    <span className="logoText clickable" onClick={() => {navigate("/");window.location.reload();}}>{"Sizej Web"}</span>

                    <div className="flex"/>

                    <RoundButton title={"Přihlásit se"} style={{marginRight: 20}} onClick={() => this.openLogin()} />
                </div>

                <div className="verticalStack flex" style={{height: "calc(100vh - 80px)", overflowY: "scroll"}}>

                    <div className="horizontalStackCenter tableListingBar">
                        <span className="fontPoppinsRegular13Gray clickable" onClick={() => this.goToPublicSongs()} style={{paddingLeft: "20px"}}>{"Zpět na seznam veřejných chval"}</span>
                        <div className="flex"/>
                    </div>

                    <div className="universalTile" style={{marginTop: 0}}>
                        <div className="verticalStack">
                            <span className="fontPoppinsSemiBold15">{"Název: "}<span className="fontPoppinsSemiBold15Blue">{this.state.songObject.title}</span></span>
                            <div style={{height: 10}}></div>
                            <span className="fontPoppinsRegular13DarkGray">{"Autor: "}<span className="fontPoppinsRegular13Gray">{this.state.songObject.author != "" ? this.state.songObject.author : "-"}</span></span>
                            <span className="fontPoppinsRegular13DarkGray">{"Capo: "}<span className="fontPoppinsRegular13Gray">{this.state.songObject.capo}</span></span>
                        </div>
                    </div>

                    <div className="universalTile">
                        <div className="allowSelectText">
                            <span className="fontPoppinsRegular13" style={{fontSize: this.state.fontSize, whiteSpace: "pre-wrap"}}>
                                {replacedText}
                            </span>
                        </div>
                    </div>

                    <div className="universalTile" style={{marginBottom: 24}}>
                        <div className="verticalStack">
                            <div className={this.state.windowHeight >= 900 ? "horizontalStack" : "verticalStack"}>

                                <div className="horizontalStack">

                                    <div className="circleButtonTransposeContainer" style={{backgroundColor: "#d4daf9", width: "auto"}}>
                                        <select name="songPublic" id="songPublic" className="profileTileInputText fontPoppinsRegular13 flex clickable" style={{backgroundColor: "#d4daf9"}} value={this.getKeyThatIsShown(this.state.songObject.song_key)} onChange={e => {this.transposeToKey(e.target.value, this.state.songObject.song_key, this.state.songId);}}>
                                            <option value={"null"}>{"Tónina: null"}</option>
                                            <option value={"C"}>{"Tónina: C"}</option>
                                            <option value={"C#"}>{"Tónina: C#"}</option>
                                            <option value={"D"}>{"Tónina: D"}</option>
                                            <option value={"D#"}>{"Tónina: D#"}</option>
                                            <option value={"E"}>{"Tónina: E"}</option>
                                            <option value={"F"}>{"Tónina: F"}</option>
                                            <option value={"F#"}>{"Tónina: F#"}</option>
                                            <option value={"G"}>{"Tónina: G"}</option>
                                            <option value={"G#"}>{"Tónina: G#"}</option>
                                            <option value={"A"}>{"Tónina: A"}</option>
                                            <option value={"A#"}>{"Tónina: A#"}</option>
                                            <option value={"H"}>{"Tónina: H"}</option>
                                        </select>
                                    </div>

                                    <div style={{width: 10}}></div>

                                    <div className="circleButtonTransposeContainer clickable" style={{backgroundColor: "#d4daf9"}} onClick={() => this.transposeUp()}>
                                        <img className="circleButton" style={{width: "10.5px", height: "16px", transform: "rotate(270deg)"}} src={icon_expand}/>
                                    </div>

                                    <div style={{width: 10}}></div>

                                    <div className="circleButtonTransposeContainer clickable" style={{backgroundColor: "#d4daf9"}} onClick={() => this.transposeDown()}>
                                        <img className="circleButton" style={{width: "10.5px", height: "16px", transform: "rotate(90deg)"}} src={icon_expand}/>
                                    </div>
                                </div>

                                <div style={{width: 10, height: 10}}></div>

                                <div className="horizontalStack">
                                    <RoundButton title={"Tisk"} icon={icon_print} onClick={() => this.printSong()} />

                                    <iframe id="ifmcontentstoprint" style={{height: "0px", width: "0px", position: "absolute"}}></iframe>

                                    <div style={{width: 10}}></div>

                                    <RoundButton title={"+"} onClick={() => this.textSizeUp()} />

                                    <div style={{width: 10}}></div>

                                    <RoundButton title={"-"} onClick={() => this.textSizeDown()} />
                                </div>

                                <div style={{width: 10, height: 10}}></div>

                                <div className="horizontalStack">
                                    <RoundButton title={"Zobrazit/schovat čísla"} onClick={() => this.onNumChordsButtonClicked()} />
                                </div>

                                <div style={{width: 10}}></div>
                            </div>
                            <div style={{height: 10}}></div>
                            <span className="fontPoppinsSemiBold15">{"Více informací o chvále"}</span>
                            <div style={{height: 10}}></div>
                            <span className="fontPoppinsRegular13DarkGray">{"Tempo: "}<span className="fontPoppinsRegular13Gray">{this.state.songObject.tempo}{" bpm"}</span></span>
                            <span className="fontPoppinsRegular13DarkGray">{"Tónina: "}<span className="fontPoppinsRegular13Gray">{this.state.songObject.song_key}</span></span>
                            <br/>
                            <div className="horizontalStack">
                                <span className="fontPoppinsRegular13DarkGray">{"Tagy: "}</span>
                                {this.state.songTags.map(function (item) {
                                    return (
                                        <label className="fontPoppinsRegular13" style={{marginLeft: 10, marginBottom: 0, border: "1px solid #556EE6", color: "#556EE6", whiteSpace: "nowrap", borderRadius: 5, paddingLeft: 5, paddingRight: 5}}>{item.id_tag}</label>
                                    )
                                }.bind(this))}
                            </div>
                            <br/>
                            <span className="fontPoppinsRegular13DarkGray">{"Originální video: "}</span>

                            <div className="embedVideo">
                                {this.state.songObject.original_url.includes("https://www.youtube.com/embed") ?
                                    <iframe
                                        src={this.state.songObject.original_url}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        title="Embedded youtube"
                                    />
                                : <span className="fontPoppinsRegular13Gray">{"Pro tuto chválu jste neuložil odkaz na Youtube."}</span>}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default PublicSong;
